import React from "react";

const AudioCard = ({ titel, beschriftung, url }) => (
  <div className="p-4 h-full flex flex-col justify-between">
    <h3 className="font-header text-lg mb-2">{titel}</h3>
    <p className="text-gray-400">{beschriftung}</p>
    <audio
      controls
      className="border border-gray rounded-lg mt-4 justify-self-end"
    >
      <source src={url} type="" />
    </audio>
  </div>
);

export default AudioCard;
