import React, { useState, useEffect } from "react";

const NewsletterForm = () => {
  const [showForm, setShowForm] = useState(false);
  const [dismissed, setDismissed] = useState(false);

  useEffect(() => {
    const isBrowser = typeof window !== "undefined";

    if (isBrowser) {
      const dismissedFromStorage =
        JSON.parse(window.localStorage.getItem("newsletterDismissed")) || false;
      setDismissed(dismissedFromStorage);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (dismissed) return; // If dismissed, do not show form
      const currentScrollY = window.scrollY;
      setShowForm(currentScrollY > 200); // Show form when scrolled down 200px
    };

    // Add event listener if in browser environment
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);

      // Clean up function to remove event listener
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [dismissed]); // Dependency array includes dismissed to re-run effect when it changes

  const handleClose = () => {
    setShowForm(false);
    setDismissed(true);
    // Save dismissal to local storage if in browser
    if (typeof window !== "undefined") {
      window.localStorage.setItem("newsletterDismissed", "true");
    }
  };

  return (
    <div
      className={`md:fixed bottom-5 right-5 transition-opacity duration-500 ${
        showForm ? "opacity-100" : "opacity-0"
      } z-50`}
      style={{ transitionProperty: "opacity", transitionDuration: "500ms" }}
    >
      {showForm && (
        <div className="max-w-md p-3 text-left bg-white rounded-lg shadow-lg">
          <button
            onClick={handleClose}
            className="absolute top-0 right-0 mt-2 mr-3 text-gray-400 hover:text-gray-500"
            aria-label="Close"
          >
            <span>&times;</span>
          </button>
          <h2 className="mb-2 text-sm">
            Möchten Sie gerne per Newsletter über aktuelle Konzerte und
            Aktivitäten informiert werden?
          </h2>

          <a
            target="_blank"
            href="https://subscribe.newsletter2go.com/?n2g=kh2n1907-in07k7o3-3l2"
            className="inline-block px-6 py-2 text-sm text-center text-white transition-all border border-transparent rounded hover:border-black bg-primary hover:bg-white hover:text-black hover:shadow-lg"
          >
            Zur Newsletter-Anmeldung
          </a>
        </div>
      )}
    </div>
  );
};

export default NewsletterForm;
