import * as React from "react";
import { graphql, Link, Script } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import moment from "moment";
import { Helmet } from "react-helmet";

import SEO from "../components/seo";
import Layout from "../components/Layout";
import KonzertKarte from "../components/KonzertKarte";
import LazyIframe from "../components/LazyIframe";
import AudioCard from "../components/AudioCard";
import NewsletterForm from "../components/NewsletterFormCard";

const IndexPage = ({ data }) => {
  const { hero, media, duo, presse, kontakt, vita, termine } =
    data?.wpPage.frontPage;

  const downloads = data?.downloads.content;

  const useSubForm = () => {
    React.useEffect(() => {}, []);
  };
  return (
    <div>
      <SEO
        keywords={[`Hanna Roos`, `Konzert`]}
        title={"Hanna Roos"}
        description={"Mezzosopranistin und Altistin"}
      />
      <Layout isDarkAtHead={false}>
        <NewsletterForm />
        <section className="grid w-full" style={{ maxHeight: 800 }}>
          <GatsbyImage
            image={getImage(hero.backgroundImage.localFile.childImageSharp)}
            className="w-full h-full"
            quality={90}
            objectPosition="50% 50%"
            imgStyle={{ maxHeight: 800 }}
            style={{ maxHeight: 800, gridArea: "1/1" }}
            alt="Hanna Roos"
          />
          <div
            className="relative top-0 object-cover w-full h-full pb-40 bg-gradient-to-t from-black/10"
            style={{ gridArea: "1/1", paddingTop: 0 }}
          >
            <div
              className="container pt-64 mx-auto text-center text-white md:text-left md:pt-0"
              style={{ marginTop: 40 }}
            >
              <h1 className="text-5xl font-header">{hero.header}</h1>
              <p className="mt-4 text-xl font-light">{hero.subheader}</p>
            </div>
          </div>
        </section>

        <section id="vita" className="w-full bg-primary">
          <div className="container grid mx-auto lg:grid-cols-2 md:grid-cols-1">
            <div className="relative top-0 flex flex-col justify-center h-full p-16 pb-8 text-black bg-primary">
              <div className="">
                <div className="py-16">
                  <h2 className="text-xl leading-relaxed text-center text-black md:text-3xl font-header md:mt-0 md:text-left">
                    {vita.header}
                  </h2>
                  <p>{vita.text}</p>
                  <div className="flex flex-col max-w-sm gap-4 mx-auto mt-8 md:mx-0">
                    <Link
                      to="/leben"
                      target="_blank"
                      className="inline-block px-6 py-2 text-center text-black transition-all border border-black rounded bg-primary hover:bg-white hover:text-black hover:border-white hover:shadow-lg"
                    >
                      Persönliche Vita
                    </Link>
                    <div className="flex flex-col justify-between w-full space-y-4 text-center md:space-y-0 md:flex-row sm:text-left">
                      {vita.lebenslauf?.localFile.publicURL && (
                        <a
                          href={vita.lebenslauf?.localFile.publicURL}
                          target="_blank"
                          className="inline-block px-6 py-2 text-center text-black transition-all border border-black rounded bg-primary hover:bg-white hover:text-black hover:border-white hover:shadow-lg"
                        >
                          Klassischer Lebenslauf
                        </a>
                      )}

                      {vita.repertoire.localFile.publicURL && (
                        <a
                          href={vita.repertoire?.localFile.publicURL}
                          target="_blank"
                          className="inline-block px-6 py-2 text-center text-black transition-all border border-black rounded bg-primary hover:bg-white hover:text-black hover:border-white hover:shadow-lg"
                        >
                          Repertoire
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="-mb-24 md:py-16 md:mb-0">
              <GatsbyImage
                image={getImage(
                  vita.backgroundImage?.localFile.childImageSharp
                )}
                className="object-cover w-full h-full shadow-lg"
                quality={90}
                style={{ gridArea: "1/1", maxHeight: 800 }}
                alt=""
              />
            </div>
          </div>
        </section>

        <section id="termine" className="relative bg-white">
          <div className="container grid grid-cols-5 px-2 py-12 mx-auto mt-24 lg:gap-24 xl:gap-0">
            <div className="col-span-5 mt-10 text-center md:col-span-2 lg:col-span-1 md:text-left">
              <h2 className="text-6xl font-header">{termine.header}</h2>
              <Link
                to="/konzertarchiv"
                className="inline-block px-6 py-2 mt-6 border border-black rounded hover:bg-primary hover:border-primary hover:text-white"
              >
                Konzertarchiv ansehen
              </Link>
            </div>
            <div className="relative grid col-span-5 gap-8 mt-10 md:col-span-3 lg:col-span-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 md:ml-8">
              <GatsbyImage
                className="absolute top-0 left-0 opacity-70"
                image={getImage(data?.HannaWeiss?.localFile?.childImageSharp)}
                style={{ position: "absolute" }}
                alt=""
              />
              {data.allWpTermin.edges
                .sort(
                  (a, b) =>
                    moment(
                      a.node.termine.zeitpunktBeginn,
                      "DD.MM.YYYY HH:mm"
                    ).unix() -
                    moment(
                      b.node.termine.zeitpunktBeginn,
                      "DD.MM.YYYY HH:mm"
                    ).unix()
                )
                .map((termin) => {
                  if (
                    moment(
                      termin.node.termine.zeitpunktEnde,
                      "DD.MM.YYYY HH:mm"
                    ).isAfter()
                  ) {
                    return (
                      <KonzertKarte key={termin.node.id} termin={termin.node} />
                    );
                  }
                })}
            </div>
          </div>
        </section>

        <section id="projekte">
          <div className="grid mt-24">
            <GatsbyImage
              image={getImage(duo.backgroundImage?.localFile.childImageSharp)}
              className="object-cover w-full h-full"
              quality={90}
              style={{ gridArea: "1/1", maxHeight: 800 }}
              alt=""
            />
            <div
              className="relative top-0 flex flex-col justify-center w-full h-full text-white bg-gradient-to-r from-black/50"
              style={{ gridArea: "1/1" }}
            >
              <div className="container py-16 mx-auto text-center md:text-left">
                <div className="flex flex-col md:w-1/2 gap-y-8">
                  <h2 className="text-6xl font-header">{duo.header}</h2>
                  <p>{duo.text}</p>
                  <Link
                    to="/projekte"
                    className="max-w-xs px-6 py-2 mx-auto text-center text-white border border-white rounded md:mx-0 bg-none hover:bg-white hover:text-black hover:shadow-lg"
                  >
                    Mehr
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="media" className="relative bg-white">
          <div className="container grid grid-cols-5 gap-8 pt-24 mx-auto">
            <div className="order-1 col-span-5 text-center xl:order-2 xl:col-span-2 lg:col-span-5 md:col-span-5 xl:text-right">
              <h2 className="text-6xl leading-relaxed font-header">
                {media.header}
              </h2>
              <Link
                className="inline-block px-6 py-2 mt-6 border border-black rounded hover:bg-black hover:text-white"
                to={media.buttonLink}
              >
                Zur Foto-Galerie
              </Link>
            </div>
            <div className="order-2 col-span-5 xl:order-1 xl:col-span-3 lg:col-span-5 md:col-span-5">
              <div className="grid gap-8 md:grid-cols-2">
                {media.mediaItems.map((item) => (
                  <div key={item.index}>
                    {item.type == "video" ? (
                      <div className="bg-gray-200 shadow-lg">
                        <LazyIframe
                          title={item.titel}
                          youtubeID={item.videoId}
                        />
                      </div>
                    ) : (
                      <div className="h-full bg-gray-100 shadow-lg">
                        <AudioCard
                          beschriftung={item.beschriftung}
                          titel={item.titel}
                          url={item.audioFileLink?.localFile.publicURL}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section id="presse">
          <div className="mt-6 bg-primary py-60">
            <div className="container grid px-2 mx-auto text-white md:grid-cols-2 gap-y-12">
              <div className="text-center md:text-left ">
                <h2 className="mb-4 text-6xl font-header">Presse</h2>
                <p>Resonanz und Rückmeldung zu meinen Konzerten.</p>
                <Link
                  to="/presse"
                  className="block max-w-xs px-6 py-2 mx-auto mt-8 text-center text-white border border-white rounded md:mx-0 bg-none hover:bg-white hover:text-black hover:shadow-lg"
                >
                  Mehr
                </Link>
              </div>
              <div
                className="mt-6 text-2xl text-center font-header"
                dangerouslySetInnerHTML={{ __html: presse }}
              />
            </div>
          </div>
        </section>

        <section id="downloads">
          <div className="mt-6 bg-white py-60">
            <div className="container grid px-2 mx-auto md:grid-cols-2 gap-y-12">
              <div
                className="flex flex-col max-w-xs mx-auto mt-6 space-y-2 text-sm font-light text-center text-white"
                dangerouslySetInnerHTML={{ __html: downloads }}
              />
              <div className="text-center md:text-left ">
                <h2 className="mb-4 text-6xl font-header">Downloads</h2>
                <p>
                  Gerne stelle ich Ihnen hier weitere Materialien zur Verfügung.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="kontakt">
          <div className="grid md:grid-cols-2">
            <div className="px-12 py-24 text-right">
              <h2 className="text-4xl font-header">Treten Sie in Kontakt!</h2>
              <div className="mt-4 mb-10 opacity-75">
                <p>
                  <b>Hanna Roos</b> | Alt/Mezzosopran
                </p>
                <p>0174-7736315 </p>
                <a href="mailto:mail@hanna-roos.de">mail@hanna-roos.de</a>
              </div>
              <h2 className="mt-24 text-4xl font-header">Newsletter</h2>
              {/* <div
                className="mt-12"
                dangerouslySetInnerHTML={{ __html: kontakt.newsletterText }}
              /> */}
              <p className="mt-6 mb-6 opacity-75">
                Gefällt Ihnen meine Arbeit und Sie möchten gerne per Newsletter
                über aktuelle Konzerte und Aktivitäten informiert werden? Dann
                tragen Sie sich bitte mit Ihrer Mailadresse bei meinem
                Newsletter ein.
              </p>

              <a
                target="_blank"
                href="https://subscribe.newsletter2go.com/?n2g=kh2n1907-in07k7o3-3l2"
                className="inline-block px-6 py-2 text-center text-white transition-all border border-transparent rounded hover:border-black bg-primary hover:bg-white hover:text-black hover:shadow-lg"
              >
                Zur Newsletter-Anmeldung
              </a>

              {/* <Script
                id="newsletter-form"
                className="text-left"
                dangerouslySetInnerHTML={{
                  __html: `!function(e,t,n,c,r,a,i){e.Newsletter2GoTrackingObject=r,e[r]=e[r]||function(){(e[r].q=e[r].q||[]).push(arguments)},e[r].l=1*new Date,a=t.createElement(n),i=t.getElementsByTagName(n)[0],a.async=1,a.src=c,i.parentNode.insertBefore(a,i)}(window,document,"script","https://static.newsletter2go.com/utils.js","n2g");var config = {"container": {"type": "div","class": "","style": ""},"row": {"type": "div","class": "","style": "margin-top: 15px;"},"columnLeft": {"type": "div","class": "","style": ""},"columnRight": {"type": "div","class": "","style": ""},"label": {"type": "label","class": "","style": ""}};n2g('create', 'kh2n1907-in07k7o3-3l2');n2g('subscribe:createForm', config);`,
                  // __html: `!function(e,t,n,c,r,a,i){e.Newsletter2GoTrackingObject=r,e[r]=e[r]||function(){(e[r].q=e[r].q||[]).push(arguments)},e[r].l=1*new Date,a=t.createElement(n),i=t.getElementsByTagName(n)[0],a.async=1,a.src=c,i.parentNode.insertBefore(a,i)}(window,document,"script","https://static.newsletter2go.com/utils.js","n2g");var config = {"container": {"type": "div","class": "","style": ""},"row": {"type": "div","class": "","style": "margin-top: 15px;"},"columnLeft": {"type": "div","class": "","style": ""},"columnRight": {"type": "div","class": "","style": ""},"label": {"type": "label","class": "","style": ""}};n2g('create', 'kh2n1907-72op5937-18ou');n2g('subscribe:createForm', config);`,
                }}
              /> */}
            </div>
            <div>
              <GatsbyImage
                image={getImage(
                  kontakt.backgroundImage.localFile.childImageSharp
                )}
                className="object-cover w-full h-full"
                quality={90}
                style={{ gridArea: "1/1" }}
                alt=""
              />
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query IndexQuery {
    downloads: wpPage(slug: { eq: "downloads" }) {
      id
      content
    }
    wpPage(isFrontPage: { eq: true }) {
      id
      title
      uri
      frontPage {
        presse
        vita {
          buttonLink1
          buttonLink2
          buttonLink3
          header
          text
          lebenslauf {
            localFile {
              publicURL
            }
          }
          personlicheVita {
            localFile {
              publicURL
            }
          }
          repertoire {
            localFile {
              publicURL
            }
          }
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 90)
              }
            }
          }
        }
        termine {
          header
        }
        media {
          buttonLink
          header
          mediaItems {
            beschriftung
            titel
            type
            videoId
            audioFileLink {
              localFile {
                publicURL
              }
            }
          }
        }
        kontakt {
          header
          newsletterText
          shortcode
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 90)
              }
            }
          }
        }
        hero {
          buttonsHtml
          header
          headerKopie
          subheader
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 90)
              }
            }
          }
        }
        duo {
          buttonLink
          header
          text
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 90)
              }
            }
          }
        }
      }
    }

    allWpTermin {
      edges {
        node {
          title
          uri
          id
          termine {
            weitereInfo
            zeitpunktBeginn
            zeitpunktEnde
            ort
          }
        }
      }
    }

    HannaWeiss: wpMediaItem(
      filename: { eq: "HANNA_ROOS_006_byFelixGroteloh-scaled.jpg" }
    ) {
      id
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

export default IndexPage;
