import React from "react";
import { Link } from "gatsby";

const KonzertKarte = ({ termin }) => {
  const { ort, weitereInfo, zeitpunktBeginn, zeitpunktEnde } = termin.termine;
  const { title, uri, id } = termin;
  return (
    <div className="relative p-10 transition-all shadow-md hover:shadow-xl hover:bg-primary">
      <h3 className="font-bold">{title}</h3>
      <div>
        {ort} | {zeitpunktBeginn}
      </div>
      <div className="mt-4">{weitereInfo}</div>
      <Link
        className="absolute top-0 bottom-0 left-0 right-0"
        to={termin.uri}
      />
    </div>
  );
};

export default KonzertKarte;
